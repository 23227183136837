import React, { useEffect, useRef, useState } from "react";

import {
  ADD_CREDITS_SUCCESS_MODAL,
  BRANCH_OPTIONS,
  SELECT_BRANCH_MODAL,
  TERMS_AND_CONDITIONS_MODAL,
  VOTED_SUCCESS_MODAL,
} from "../../../constants";
import { scrollIntoView } from "../../../utils/domManipulation";
import { useModal } from "../../../hooks/useModal";
import {
  useInform,
  useLocal,
  useRadioidByNameseo,
} from "../../../hooks/useApi";
import { useTerms } from "../../../hooks/useTerms";
import { useScrollObserver } from "../../../hooks/useScrollObserver";

import DevPre from "../../dev/DevPre";

import Button from "../../basic/Button";
import Loader from "../../basic/Loader";

import Boxes from "./components/Boxes";
import NowPlaying from "./components/NowPlaying";

import "./styles/sonicbox.scss";
import MenuToggleSVG from "../../../inline-svg/menu-toggle.svg";
import { T } from "../../../hooks/useLanguage";

import PlayIcon from "../../../images/icon-play.png";
import { useLocation, useNavigate } from "@reach/router";
import SearchMusic from "./components/SearchMusic";
import SonicboxHeader from "./components/SonicboxHeader";
import Suggested from "./components/Suggested";
import Artists from "./components/Artists";
import useTheme from "../../../hooks/useTheme";
import { getCookie } from "../../../helpers/cookies";
import usePoll from "../../../hooks/usePoll";

const Sonicbox = ({ nameseo }) => {
  const { setTheme } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { openModal: openSelectBranchModal } = useModal(SELECT_BRANCH_MODAL);
  const { lastModal } = useModal(ADD_CREDITS_SUCCESS_MODAL);
  const {
    openModal: openTermsModal,
    currentModal,
    closeModal,
  } = useModal(TERMS_AND_CONDITIONS_MODAL);
  const [openPeekTerms, setOpenPeekTerms] = useState(false);
  const {
    ready: termsReady,
    extraQuery,
    userExtra,
    termsNameseo,
    termsRequired,
    ...termsQuery
  } = useTerms();

  const headerRef = useRef();
  const musicRef = useRef();
  const boxesRef = useRef();

  const { ref: songsRef, inView } = useScrollObserver("songs");
  const [selectedParent, setParent] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);

  const { radioid } = useRadioidByNameseo(nameseo);
  const { lastPoll } = usePoll(radioid, nameseo);

  const isSearch = location.search.indexOf("?s") === 0;

  const { processPendingEvents } = useInform(nameseo);

  const extraLastUpdate = useRef(0);
  useEffect(() => {
    if (userExtra && extraQuery.dataUpdatedAt !== extraLastUpdate.current) {
      extraLastUpdate.current = extraQuery.dataUpdatedAt;
      processPendingEvents();
    }
  }, [processPendingEvents, userExtra, extraQuery.dataUpdatedAt]);

  const {
    isLoading,
    data,
    isError,
    error,
    radioid: currentRadioid,
  } = useLocal(radioid);

  useEffect(() => {
    console.log("local radioid", radioid);
  }, [radioid]);

  useEffect(() => {
    setTimeout(() => {
      if (
        typeof BRANCH_OPTIONS[nameseo] !== "undefined" &&
        currentModal !== SELECT_BRANCH_MODAL &&
        currentModal !== TERMS_AND_CONDITIONS_MODAL
      ) {
        const branches = BRANCH_OPTIONS[nameseo].branches;
        let chosenBranch = getCookie(nameseo);
        // console.log(chosenBranch);
        if (Object.keys(branches).indexOf(chosenBranch) === -1) {
          openSelectBranchModal({ nameseo: nameseo });
        } else {
          if (chosenBranch !== nameseo) {
            navigate(`/sonicbox/${chosenBranch}`);
          }
        }
      }
    }, 1000);
  }, [nameseo, openSelectBranchModal, currentModal, navigate]);

  useEffect(() => {
    // console.log("A local currentRadioid", currentRadioid, data);
    if (
      data?.name &&
      (data.name.indexOf("Falabella") > -1 ||
        data.name.indexOf("falabella") > -1)
    ) {
      setTheme("falabella");
    } else if (data?.name && data.name.indexOf("Casper Radio") > -1) {
      setTheme("casper");
    } else {
      setTheme("default");
    }
  }, [setTheme, data, currentRadioid]);

  useEffect(() => {
    if (lastModal === VOTED_SUCCESS_MODAL) {
      // console.log("-------------- VOTED_SUCCESS_MODAL ---------------");
      if (typeof headerRef.current !== "undefined" && headerRef.current) {
        scrollIntoView(headerRef.current);
      }
    }
  }, [lastModal]);

  // useEffect(() => {
  //   if (data?.radiobg && data.radiobg !== "") {
  //     document.body.style.backgroundImage = `url(${data.radiobg})`;
  //   }
  //   return () => (document.body.style.backgroundImage = null);
  // }, [data]);

  //const { isLoading, data, isError, error } = useLocal(radioid, null);

  const handleLetsPlay = () => {
    if (musicRef.current !== undefined) {
      scrollIntoView(musicRef.current, 75);
    }
  };

  const handleOnSelectBox = (selectedBox) => {
    setSelectedBox(selectedBox);
    // console.log(
    //   "scrollIntoView songsWrapperRef",
    //   songsWrapperRef,
    //   songsWrapperRef.current
    // );
    navigate(`/sonicbox/${nameseo}?s=&p=${selectedBox}`);
  };

  const handleScrollBack = () => {
    handleLetsPlay();
  };

  useEffect(() => {
    // console.log("useEffect from termsQuery", termsQuery?.data);
  }, [termsQuery.dataUpdatedAt, termsQuery?.data]);

  useEffect(() => {
    if (termsRequired) {
      // console.log("termsQuery, extraQuery", termsQuery?.data);
      // console.log("extraQuery", extraQuery?.data?.data);
      let extraArray = [];
      let parsed = {};
      if (extraQuery?.data?.data?.extra) {
        try {
          parsed = JSON.parse(extraQuery?.data?.data?.extra);
          extraArray = Object.keys(parsed);
        } catch (e) {
          console.log("error parsing extra", e);
          extraArray = extraQuery?.data?.data?.extra.split(",");
        }
      }
      const termsAccepted =
        extraArray.includes("terms") ||
        extraArray.includes("tos") ||
        (extraArray.includes("declare") && extraArray.includes("authorize")) ||
        (extraArray.includes("checkTerms") && parsed?.checkTerms);
      if (termsQuery?.data && extraQuery?.data) {
        // console.log(
        //   "currentModal",
        //   currentModal,
        //   TERMS_AND_CONDITIONS_MODAL,
        //   termsQuery.acceptTerms.status,
        //   termsAccepted
        // );
        if (
          termsQuery.acceptTerms.status === "idle" &&
          !termsAccepted &&
          currentModal !== TERMS_AND_CONDITIONS_MODAL
        ) {
          openTermsModal({ nameseo: nameseo });
        }
        if (
          termsAccepted &&
          currentModal === TERMS_AND_CONDITIONS_MODAL &&
          !openPeekTerms
        ) {
          // console.log("now close terms modal");
          closeModal();
        }
      }
    }
  }, [
    termsQuery?.data,
    extraQuery?.data?.data?.extra,
    nameseo,
    openTermsModal,
    currentModal,
    closeModal,
    openPeekTerms,
    radioid,
    termsQuery.acceptTerms,
    termsQuery.acceptTerms.status,
    extraQuery?.data,
    termsRequired,
  ]);

  if (!termsReady) {
    return (
      <>
        <DevPre style={{ zIndex: 9999 }}>
          {typeof termsReady} {JSON.stringify(termsReady, null, " ")}{" "}
          {termsQuery.status} {typeof termsQuery.error} {extraQuery.status}
          {"\n"}
          error: {JSON.stringify(termsQuery?.error, null, " ")}
        </DevPre>
        <Loader />
      </>
    );
  }

  return (
    <div className="container">
      <DevPre style={{ zIndex: 9999 }}>
        {termsNameseo} {extraQuery.status}
        {"\n"}
        lastpoll: {typeof lastPoll}{" "}
        {lastPoll === null ? "null" : lastPoll.toString()}
        {"\n"}
        {typeof termsRequired}{" "}
        {termsRequired ? "termsRequired" : "!termsRequired"}
        {"\n"}
        {termsReady ? "termsReady" : "not termsReady"}
        {"\n"}
        {JSON.stringify(extraQuery?.dataUpdatedAt, null, " ")}
        {"\n"}
        {JSON.stringify(extraQuery?.data, null, " ")}
        {"\n"}
        {"userExtra: "}
        {typeof userExtra} {JSON.stringify(userExtra, null, " ")}
      </DevPre>
      {isError ? (
        <DevPre style={{ color: "red" }}>
          {JSON.stringify(error, null, " ")}
        </DevPre>
      ) : isLoading ? (
        <Loader />
      ) : isSearch ? (
        <SearchMusic nameseo={nameseo} />
      ) : (
        <div className={["sonicbox"].join(" ")}>
          <NowPlaying {...data} radioid={radioid} />
          <header
            id="header"
            style={{
              backgroundImage: data?.radiobg ? `url(${data.radiobg})` : "",
            }}
            ref={headerRef}
          >
            <SonicboxHeader {...data} radioid={radioid} />
            <Button className="cta" size={"big"} onClick={handleLetsPlay}>
              <img src={PlayIcon} alt="play" className="icon" />
              <T>sonicbox.cta</T>
            </Button>
            {termsQuery?.data &&
              termsQuery?.data?.text !== "" &&
              (!!termsQuery?.links ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {termsQuery.links.map((link, index) => (
                    <Button
                      className="brand"
                      as="link"
                      onClick={() => {
                        window.open(link.href, "_blank");
                      }}
                      key={index}
                      style={{
                        marginLeft: index === 0 ? 0 : 10,
                        opacity: 0.5,
                      }}
                    >
                      {link.textContent}
                    </Button>
                  ))}
                </div>
              ) : (
                <Button
                  className="brand"
                  as="link"
                  onClick={() => {
                    setOpenPeekTerms(true);
                    openTermsModal({
                      nameseo: nameseo,
                      isPeeking: true,
                      setIsPeeking: setOpenPeekTerms,
                    });
                  }}
                  style={{
                    marginLeft: "auto",
                    display: "block",
                    marginTop: 20,
                    marginBottom: -14,
                    marginRight: -8,
                    fontSize: 12,
                    opacity: 0.5,
                  }}
                >
                  <T>sonicbox.terms</T>
                </Button>
              ))}
          </header>
          <div id="boxes" ref={musicRef}>
            <Boxes
              ref={boxesRef}
              playlists={
                data
                  ? data.playlists.sort((playlist) =>
                      playlist.playlistid === "737" ? -1 : 1
                    )
                  : []
              }
              parents={data ? data.parents : []}
              onSelectBox={handleOnSelectBox}
              selectedBox={selectedBox}
              boxesRef={boxesRef}
              songsRef={songsRef}
              boxesOffset={80}
              songsOffset={180}
              selectedParent={selectedParent}
              setParent={setParent}
              title={data ? <T name={data.name}>sonicbox.boxestitle</T> : ""}
            />
          </div>
          <div id="suggested">
            <Suggested radio={data} />
          </div>
          <div id="artists">
            <Artists radio={data} />
          </div>
        </div>
      )}
      <Button
        className={["blue", "scroll-back-button", inView ? "show" : ""].join(
          " "
        )}
        onClick={handleScrollBack}
      >
        <img src={MenuToggleSVG} alt="ir arriba" />
      </Button>
    </div>
  );
};

export default Sonicbox;
